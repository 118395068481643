import { ValueOf } from 'types/generic';

// Centralized Enum for All Event Names
export const enum TrackingEvents {
  LOGIN = 'login',
  ARCHIVE_VIEWED = 'archive_viewed',
  ADOPTION_MODAL_OPENED = 'adoption_modal_opened',
  INVOICE_APPROVE_BTN_CLICKED = 'invoice_approve_btn_clicked',
  INVOICE_REQUEST_BTN_CLICKED = 'invoice_request_btn_clicked',
  DOCUMENT_PREVIEW_OPENED = 'document_preview_opened',
  PROMOTION_MODAL_OPENED = 'promotion_modal_opened',
  // Add other events as needed
}

// Centralized Enum for All Adoption Names
export const enum AdoptionFeatures {
  MOBILE_APP = 'mobile app',
  // Add other adoption features as needed
}

// Centralized Enum for All Promotion Names
export const enum PromotionFeatures {
  DOCUMENT_PREVIEW = 'document preview',
  // Add other promotion features as needed
}

export const enum DocumentPreviewEventLocations {
  ARCHIVE = 'archive',
  MANAGE_RELATIONSHIPS = 'manage relationships',
}

interface LoginEventProperties {
  keycloak_auth_client: string;
  keycloak_session_id: string;
  user_agent: string;
}

interface ArchiveViewedProperties {
  archive_documents_count: number;
}

interface AdoptionModalOpenedProperties {
  feature: ValueOf<AdoptionFeatures>;
  event_location?: string;
}

interface PromotionModalOpenedProperties {
  feature: ValueOf<PromotionFeatures>;
  event_location?: string;
}

interface InvoiceApproveBtnClickedProperties {
  document_id: string;
  note?: string | null;
  tax_code?: string | null;
  posting_text?: string | null;
  accounts_payable?: string | null;
  cost_center?: string | null;
  cost_object?: string | null;
  extra_cost_info?: string | null;
  general_ledger_account?: string | null;
  social_artist_security?: string | null;
  is_fast_approval: boolean;
  is_last_step: boolean;
}

interface InvoiceRequestBtnClickedProperties
  extends Omit<
    InvoiceApproveBtnClickedProperties,
    'is_fast_approval' | 'is_last_step'
  > {}

interface DocumentPreviewOpenedProperties {
  document_id: string;
}

export type EventProperties = {
  [TrackingEvents.LOGIN]: LoginEventProperties;
  [TrackingEvents.ARCHIVE_VIEWED]: ArchiveViewedProperties;
  [TrackingEvents.ADOPTION_MODAL_OPENED]: AdoptionModalOpenedProperties;
  [TrackingEvents.INVOICE_APPROVE_BTN_CLICKED]: InvoiceApproveBtnClickedProperties;
  [TrackingEvents.INVOICE_REQUEST_BTN_CLICKED]: InvoiceRequestBtnClickedProperties;
  [TrackingEvents.DOCUMENT_PREVIEW_OPENED]: DocumentPreviewOpenedProperties;
  [TrackingEvents.PROMOTION_MODAL_OPENED]: PromotionModalOpenedProperties;
  // Add other events and properties as needed
};
