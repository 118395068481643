import { IamAuthType } from 'generated-types/graphql.types';
import Keycloak, { KeycloakLoginOptions } from 'keycloak-js';
import {
  UserRealm,
  keycloakRepresentationToUser,
} from 'utils/authorization-helper';
import { prefix } from '../../utils/localStorage';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';

const authServerUrl = import.meta.env.REACT_APP_KEYCLOAK_AUTH_ENDPOINT;
const realmName = import.meta.env.REACT_APP_KEYCLOAK_AUTH_REALM;
const availableRealmsKey = `${prefix}_available_user_realms`;
const authTokenStorageKey = `${prefix}_auth`;

/**
 * These are the Levels of Authentication defined in Keycloak.
 */
const KeycloakLevelOfAuthentication = {
  bronze: 'bronze',
  silver: 'silver',
  gold: 'gold',
  platinum: 'platinum',
} as const;

const setAvailableUserRealms = (realms: UserRealm[]) => {
  localStorage.setItem(availableRealmsKey, JSON.stringify(realms));
};

const getAvailableUserRealms = () => {
  return localStorage.getItem(availableRealmsKey);
};

export class AuthService {
  constructor(private keycloak: Keycloak) {}

  async authorizedToken(minValidity = 5) {
    // Update token if it's about to expire in 5 seconds or less
    try {
      await this.keycloak.updateToken(minValidity);
      this.saveTokenToLocalStorage();

      return this.keycloak.token;
    } catch {
      console.info('Failed to update token');
      await this.logout();
    }
  }

  saveLoginDataToLocalStorage() {
    const authTrackingData = {
      keycloak_auth_client: this.keycloak.clientId,
      keycloak_session_id: this.keycloak.sessionId,
      user_agent: navigator.userAgent,
    };

    localStorage.setItem(
      TrackingEvents.LOGIN,
      JSON.stringify(authTrackingData)
    );
  }

  saveTokenToLocalStorage() {
    const {
      refreshToken,
      token,
      idToken,
      tokenParsed: { exp = 0 } = {},
    } = this.keycloak;

    localStorage.setItem(
      authTokenStorageKey,
      JSON.stringify({
        refreshToken,
        token,
        idToken,
        expires: exp ? exp * 1000 : null,
      })
    );
  }

  async updateUserProfile() {
    const profile: any = await this.keycloak.loadUserProfile();

    if (profile?.attributes) {
      const { realms } = keycloakRepresentationToUser({
        attributes: profile.attributes,
      });

      if (realms) {
        setAvailableUserRealms(realms);
      }
    }
  }

  isAuthenticated() {
    return this.keycloak.authenticated;
  }

  getIdToken() {
    return this.keycloak.idToken;
  }

  getAvailableRealms(): UserRealm[] {
    return JSON.parse(getAvailableUserRealms() as string) || [];
  }

  async authKeycloak() {
    const tokenInfo = localStorage.getItem(authTokenStorageKey);
    let tokenStillValid = false;
    let storedToken;
    let storedIdToken;
    let storedRefreshToken;

    if (tokenInfo) {
      try {
        const { expires, token, idToken, refreshToken } = JSON.parse(tokenInfo);
        if (expires) {
          tokenStillValid = +new Date() < expires;
          storedToken = token;
          storedIdToken = idToken;
          storedRefreshToken = refreshToken;
        }
      } catch (e) {
        console.info('Failed to parse access token:' + tokenInfo);
      }
    }

    if (tokenStillValid) {
      try {
        const isAlreadyAuthenticated = this.isAuthenticated();

        if (isAlreadyAuthenticated) {
          return isAlreadyAuthenticated;
        }

        const isAuthenticated = await this.initKeycloakWithToken({
          storedToken,
          storedIdToken,
          storedRefreshToken,
        });

        if (isAuthenticated) {
          return isAuthenticated;
        }
      } catch (e) {
        console.info('Failed to init with existing token', e);
        await this.logout();
      }
    }

    return this.loginKeycloak();
  }

  async initKeycloakWithToken({
    storedToken,
    storedIdToken,
    storedRefreshToken,
  }: {
    storedToken?: string;
    storedIdToken?: string;
    storedRefreshToken?: string;
  }) {
    return new Promise<boolean | undefined>((resolve, reject) => {
      this.keycloak
        .init({
          timeSkew: 0,
          token: storedToken,
          idToken: storedIdToken,
          refreshToken: storedRefreshToken,
          onLoad: 'check-sso',
          // XXX hacky?
          redirectUri: window.location.href,
        })
        .then(authenticated => {
          if (!authenticated) {
            reject(new Error('auth token is invalid'));
          }

          this.saveTokenToLocalStorage();
          this.updateUserProfile()
            .then(() => {
              resolve(authenticated);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async createReauthenticateUrl(
    redirectPath: string,
    authType: IamAuthType
  ): Promise<string> {
    const pwdOnlyLoginOptions: KeycloakLoginOptions = {
      prompt: 'login',
      maxAge: 1,
      loginHint: this.keycloak.tokenParsed?.email,
      acr: {
        essential: true,
        values: [KeycloakLevelOfAuthentication.silver],
      },
    };

    const mfaLoginOptions: KeycloakLoginOptions = {
      loginHint: this.keycloak.tokenParsed?.email,
      acr: {
        essential: true,
        values: [KeycloakLevelOfAuthentication.platinum],
      },
    };

    const loginOptions =
      authType === IamAuthType.Mfa ? mfaLoginOptions : pwdOnlyLoginOptions;

    return this.keycloak.createLoginUrl({
      redirectUri: `${window.location.origin}${redirectPath}`,
      ...loginOptions,
    });
  }

  async loginKeycloak() {
    return new Promise<boolean | undefined>((resolve, reject) => {
      this.keycloak
        .init({
          onLoad: 'login-required',
          // XXX hacky?
          redirectUri: window.location.href,
        })
        .then(authenticated => {
          if (!authenticated) {
            return reject(new Error('LOGIN_UNSUCCESSFUL'));
          }

          this.saveLoginDataToLocalStorage();
          this.saveTokenToLocalStorage();
          this.updateUserProfile()
            .then(() => {
              resolve(authenticated);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async logout(): Promise<void> {
    try {
      localStorage.removeItem(authTokenStorageKey);
      localStorage.removeItem(availableRealmsKey);

      await this.keycloak.logout({
        redirectUri: window.location.origin,
      });
    } catch (error) {
      this.forceLogout();
    }
  }

  forceLogout(): void {
    const idToken = this.getIdToken();
    const clientId = this.keycloak.clientId;
    const logoutBaseUrl = `${authServerUrl}/realms/${realmName}/protocol/openid-connect/logout`;

    let logoutParams = `?post_logout_redirect_uri=${encodeURIComponent(
      window.location.origin
    )}`;

    if (idToken) {
      logoutParams += `&id_token_hint=${idToken}`;
    }

    if (clientId) {
      logoutParams += `&client_id=${clientId}`;
    }

    localStorage.removeItem(authTokenStorageKey);
    localStorage.removeItem(availableRealmsKey);

    window.location.replace(logoutBaseUrl + logoutParams);
  }
}
