// TODO this is copied from @candisio/authorization-helpers, decide what to do after migrating to VITE

export type UserRealm = {
  userId: string;
  realm: string;
};

export const USER_REALMS_KC_ATTR_PREFIX = 'userRealms';

export type CollectionAccessGroups = {
  [accessGroup: string]: string[];
};

export type UserCredentials = {
  type: string;
  value: string;
};

export enum MembershipStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type User = {
  avatarFileId?: string;
  createdTimestamp?: number;
  credentials?: UserCredentials[];
  email?: string;
  emailSubscriptions?: string[];
  emailVerified?: boolean;
  firstName?: string;
  id?: string;
  invitedTimestamp?: number;
  isGlobalRealmUser?: boolean;
  // for tracking opt-out
  trackingEnabled?: boolean;
  lastName?: string;
  locale?: string;
  realms?: UserRealm[];
  roles?: string[];
  status?: MembershipStatus;
  username?: string;
  // for backwards compatibility
  version?: string;
};

export function parseKeycloakAttributeValue(value: any): string {
  if (
    Array.isArray(value) &&
    value.length > 0 &&
    typeof value[0] === 'string'
  ) {
    return value[0];
  }
  return value;
}

export function keycloakRepresentationToUser(keycloakUser: any): User {
  const {
    id,
    username,
    emailVerified,
    email,
    firstName,
    lastName,
    createdTimestamp,
    credentials,
    attributes: {
      version: versionAttribute,
      locale,
      avatarFileId,
      trackingEnabled,
      invitedAt,
      emailSubscriptions,
      status,
      ...attributes
    },
  } = keycloakUser;

  const version = parseKeycloakAttributeValue(versionAttribute);

  const user: User = {
    id,
    username,
    emailVerified,
    email,
    firstName,
    lastName,
    createdTimestamp,
    invitedTimestamp: invitedAt,
    ...(credentials
      ? {
          credentials: credentials.map((c: any) => ({
            type: c.type,
            value: c.value,
          })),
        }
      : {}),
    locale: parseKeycloakAttributeValue(locale),
    avatarFileId: parseKeycloakAttributeValue(avatarFileId),
    roles: attributesToRoles(attributes, version),
    realms: attributesToRealms(attributes, version),
    emailSubscriptions: attributesToemailSubscriptions(emailSubscriptions),
    status,
    trackingEnabled:
      trackingEnabled !== undefined
        ? parseKeycloakAttributeValue(trackingEnabled) === 'true'
        : true,
    version: '2',
  };

  return user;
}

function attributesToemailSubscriptions(emailSubscriptions: string): string[] {
  if (emailSubscriptions) {
    return parseKeycloakAttributeValue(emailSubscriptions).split(',');
  }
  return [];
}

function attributesToRealms(
  attributes: Record<string, any>,
  version: string | undefined
): UserRealm[] | undefined | any {
  if (version === '2') {
    const realmAttributes = Object.entries(attributes).filter(([name]) =>
      name.startsWith(USER_REALMS_KC_ATTR_PREFIX)
    );
    if (realmAttributes.length) {
      return realmAttributes.map(([realmAttribute, userId]) => {
        const realmAttributeValues = realmAttribute.split('.');
        if (realmAttributeValues.length === 2) {
          return {
            realm: realmAttributeValues[1],
            userId: parseKeycloakAttributeValue(userId),
          };
        }
      });
    }
  }
  if (attributes.realms) {
    return JSON.parse(attributes.realms);
  }
}

function attributesToRoles(
  attributes: Record<string, any>,
  version: string | undefined
): string[] | undefined {
  if (attributes.roles) {
    if (version === '2') {
      return parseKeycloakAttributeValue(attributes.roles).split(',');
    } else {
      return JSON.parse(attributes.roles);
    }
  }
}
